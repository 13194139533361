//Details-open means that the sidemenu detail is open. 

//when nomenu is triggered, we want all the same things to happen as when details open is, just for the sidemenu

import React, { useState, useRef, useEffect } from "react";
import { History, Settings, ArrowUpwardOutlined, HubOutlined, Add } from "@mui/icons-material";

import { useNavigate, useLocation, matchPath } from 'react-router-dom';

import "../styles/screens/Chat/Chat.css";
import PopUp from "../components/PopUp.jsx";
import Snackbar from "../components/snackbar.jsx";
import ChatWindow from "../components/ChatWindow.jsx"

import logoDark from "../assets/logoDark.png";
import logoLight from "../assets/logoLight.png";
import logoSmall from "../assets/small-logo.png";

import { renderSettings } from "../components/sidemenus/settings.jsx";
import { ManageData, renderContext } from "../components/sidemenus/manageData.jsx";


import { getFileNames } from "../functions/api/Files.jsx";
import { useJwtToken } from '../functions/utilities/useJwtToken.jsx';
import { trendingTopics } from "../functions/utilities/trendingTopics.jsx";

import { handleSend } from "../functions/api/Ask.jsx";
import { handleSetContext, getContext } from "../functions/api/Context.jsx";
import { addSheet, getGoogleAccessTokenFromAuthCode } from "../functions/api/Google.jsx";
import { getAllThreads, renderThreads, getMessages } from "../functions/api/History.jsx";
import { getHubSpotAuthCodeAndExport, getHubSpotObjectToFields, connectHubspot } from "../functions/api/Hubspot.jsx";

function Chat({ user }) {
  /* Settings */
  const [theme, setTheme] = useState("light");
  const changeTheme = () => { setTheme(theme === "light" ? "dark" : "light"); }
  const [audioOn, setAudioOn] = useState(true);


  /* For Google and Hubspot, when user first gets to page after google/hubspot callback */
  const location = useLocation();
  const service = location.state?.service;
  const [authCode, setAuthCode] = useState(location.state?.authCode);

  /* For every API call */
  const jwtToken = useJwtToken();
  const jwtTokenLoaded = useRef(false);

  /* For signout */
  const navigate = useNavigate();

  /* for Manage Data Sidemenu */
  const [fileNames, setFileNames] = useState([]);
  const [googleFileNames, setGoogleFileNames] = useState([]);
  const [googleData, setGoogleData] = useState({ data: {}, tabNames: [], selectedTabNames: [] });

  /* Fields is a dict keyed by object name, value is properties */
  const [hubspotFields, setHubspotFields] = useState([]);
  const [hubspotSelectedFields, setHubspotSelectedFields] = useState([]);
  const [hubspotSelectedObjects, setHubspotSelectedObjects] = useState([]);
  const [selectedFiltered, setSelectedFiltered] = useState(false);
  /* Hubspot Connection Status: "fail", "loading", "success" */
  const [hubspotConnectionStatus, setHubspotConnectionStatus] = useState("loading");
  const [searchQuery, setSearchQuery] = useState("")

  /* Messages Functions */
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [isSendDisabled, setIsSendDisabled] = useState(true);
  const [threads, setThreads] = useState([]);
  const [currentThreadID, setCurrentThreadID] = useState(null);
  const [currentThreadArchived, setCurrentThreadArchived] = useState(null);
  const [messageFlag, setMessageFlag] = useState(false);

  const [selectedModel, setSelectedModel] = useState("");
  const [temperature, setTemperature] = useState(1.0);

  /* 
  Active Context File: The filename that is currently being viewed in the context, 
  null = no active context 
  "Organization Level Context" = overall context
  */
  const [activeContextFile, setActiveContextFile] = useState(null);
  const [activeContext, setActiveContext] = useState("");
  const [orgContext, setOrgContext] = useState("");
  const [keywords, setKeywords] = useState({});
  const [companyColors, setCompanyColors] = useState([]);
  const [generalContext, setGeneralContext] = useState("");
  const [chartFormatting, setChartFormatting] = useState("");
  const [contextSubmenu, setContextSubmenu] = useState("files");
  const parseCompanyInfo = async () => {
    const defaultContext = `        
    KEYWORDS:
    {"Win Rate": "The number of deals that were closed-won divided by the number of deals that were closed won or closed lost in a period of time.","Capture Rate": "The total amount associated with deals that were closed-won divided by the total amount associated that were closed won or closed lost in a period of time.","Deal Velocity": "The average number of days between a deal’s create date and close date for deals that are closed-won.","Email-Meeting Conversion Rate": "The total number of emails sent by the sales team divided by the total number of meetings booked by the sales team in a given period of time.","Meeting-Deal Conversion Rate": "The total number of meetings booked by the sales team divided by the total number of deals created by the sales team in a given period of time.","New Business Revenue": "The revenue generated from customers that have been with us for 12 months or less.","Existing Business Revenue": "The revenue generated from customers that have been with us for 13 months or more.","Churned Customer": "A customer that has stopped spending with us for at least 1 month.","Churn Rate": "The total number of customers that were churned in a period of time divided by the total number of customers that were active during that period of time."}

    COMPANY COLORS:
    ["#D74813", "#000000", "#EC8866"]
    
    CHART FORMATTING:
    {"Title": {"Bold": true, "Underline": false, "Italicize": false, "Colors": false}, "xAxis": {"Bold": false, "Underline": false, "Italicize": true, "Colors": true}, "yAxis": {"Bold": true, "Underline": false, "Italicize": true, "Colors": true}, "GridLines": {"Enabled": true}}

    GENERAL CONTEXT:
    "This company cares about accurate and insightful rev ops analysis"
    `


    const keywordPattern = /KEYWORDS:\n\s*({.*})/;
    const colorPattern = /COMPANY COLORS:\n\s*(\[[^\]]*\])/;
    const chartFormattingPattern = /CHART FORMATTING:\n\s*({.*})/;
    const contextPattern = /GENERAL CONTEXT:\n\s*"([^"]*?)"$/;

    const keywordMatch = orgContext.match(keywordPattern);
    const colorMatch = orgContext.match(colorPattern);
    const chartFormattingMatch = orgContext.match(chartFormattingPattern);
    const contextMatch = orgContext.match(contextPattern);

    // console.log(keywordMatch ? keywordMatch[1] : 'No keywords found');
    // console.log(colorMatch ? colorMatch[1] : 'No colors found');
    // console.log(chartFormattingMatch ? chartFormattingMatch[1] : 'No chart formatting found');
    // console.log(contextMatch ? contextMatch[1] : 'No context found');

    if (keywordMatch) {
      const keywordString = keywordMatch[1];
      const keywordDict = JSON.parse(keywordString.replace(/(\w+):/g, '"$1":'));
      setKeywords(keywordDict);
    }

    if (colorMatch) {
      const colorString = colorMatch[1];
      const colorList = JSON.parse(colorString);
      setCompanyColors(colorList);
    }

    if (chartFormattingMatch) {
      const chartFormattingString = chartFormattingMatch[1];
      const chartFormattingDict = JSON.parse(chartFormattingString.replace(/(\w+):/g, '"$1":'));
      setChartFormatting(chartFormattingDict);
    }

    if (contextMatch) {
      const contextString = contextMatch[1];
      setGeneralContext(contextString);
    }

    let updatedOrgContext = orgContext;

    if (!keywordMatch) {
      const defaultKeywordMatch = defaultContext.match(keywordPattern);
      if (defaultKeywordMatch) {
        updatedOrgContext = updatedOrgContext.concat('\n The values below represent keywords and definitions for this organization. Feel free to use them if relevant, but do not use them if they are not relvant to the messages in the thread. \n', defaultKeywordMatch[0]);
      }
    }

    if (!colorMatch) {
      const defaultColorMatch = defaultContext.match(colorPattern);
      if (defaultColorMatch) {
        updatedOrgContext = updatedOrgContext.concat('\n The values below represent hexcodes that should be used as the primary, secondary and tertiary colors in any visualizations you generate. \n', defaultColorMatch[0]);
      }
    }

    if (!chartFormattingMatch) {
      const defaultChartFormattingMatch = defaultContext.match(chartFormattingPattern);
      if (defaultChartFormattingMatch) {
        updatedOrgContext = updatedOrgContext.concat('\n The values below represent chart formatting prefrences in a condensed fashion. Use this information when creating visualizations to inform how the title, axis labels, and grid lines should be formatted. the Colors value represents whether or not you should use the primary color under COMPANY COLORS as the color for the given attribute. \n', defaultChartFormattingMatch[0]);
      }
    }

    if (!contextMatch) {
      const defaultContextMatch = defaultContext.match(contextPattern);
      if (defaultContextMatch) {
        updatedOrgContext = updatedOrgContext.concat('\n The value below represents some general information about the company. Feel free to use if relevant, but if not please disregard \n', defaultContextMatch[0]);
      }
    }

    if (updatedOrgContext !== orgContext) {
      await handleSetContext(jwtToken, setSnackbar, "Organization Level Context", updatedOrgContext);
    }

  }

  useEffect(() => {
    if (orgContext) {
      parseCompanyInfo();
    }
  }, [orgContext]);

  /* 
  popup Functions 
  */
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupThumbUp, setPopupThumbUp] = useState(true);
  const [popupClass, setPopupClass] = useState("hidden");

  const possibleModels = process.env.REACT_APP_POSSIBLE_MODELS.split(',');

  const handleClosePopup = async () => {
    setPopupClass("hidden");
    setPopupThumbUp(null);
  }
  const handleOpenPopup = (popupClass = "") => {
    setPopupClass(popupClass);
  }

  // This dependency ensures the effect runs only when selectedTabNames updates, timing issue putting it in handleClosePopup
  useEffect(() => {
    async function performUpload() {
      if (googleData.selectedTabNames && googleData.selectedTabNames.length > 0) {
        console.log('Google Data:', googleData);
        const successfulUpload = await addSheet(jwtToken, user, googleData, setSnackbar);
        if (successfulUpload) {
          const newFiles = await getFileNames(jwtToken, setFileNames);
          setGoogleFileNames(newFiles[1]);
          setSnackbar("upload complete!", "complete");
        };
        setGoogleData({ data: {}, tabNames: [], selectedTabNames: [] });
      }
    }
    performUpload();
  }, [googleData.selectedTabNames]);




  //used to flash the input bar when a trending question or example question is clicked
  const [notify, setNotify] = useState("");
  const toggleNotify = () => {
    setNotify("notify");
    setTimeout(() => {
      setNotify("");
    }, 500);
  }

  useEffect(() => {
    console.log('Popup Class:', popupClass)
    if (popupClass === "hidden") {
      setIsPopupOpen(false);
    }
    else {
      setIsPopupOpen(true);
    }
  }, [popupClass]);


  //Trending Topics
  const [selectedTT, setSelectedTT] = useState(null);
  const [prompts, setPrompts] = useState(["1", "2", "3", "4", "5"])
  const [sortedTopics, setSortedTopics] = useState(["1", "2", "3", "4", "5"]);
  const [sortedPrompts, setSortedPrompts] = useState(["1", "2", "3", "4", "5"]);

  useEffect(() => {
    const topics = Object.keys(trendingTopics).sort(() => Math.random() - 0.5).slice(0, 5);
    setSortedTopics(topics);

    if (process.env.REACT_APP_ENV === "dev") {
      setSelectedModel(possibleModels[0] || "");
    }
  }, []); // Re-sort only if trendingTopics changes

  useEffect(() => {
    if (selectedTT) {
      const prompts = Object.keys(trendingTopics[selectedTT]).sort(() => Math.random() - 0.5).slice(0, 5);
      setSortedPrompts(prompts);
    }
  }, [selectedTT, trendingTopics]); // Re-sort prompts only if selectedTT or trendingTopics changes

  /* 
  snackbar Functions 
  */
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  /* 
  snackbar status can be 
  1. hidden (nothing loading) 
  2. loading (snackbar status pops up) 
  3. complete (snackbar changes to complete, auto hides after 5s TODO).
  */
  const [snackbarStatus, setSnackbarStatus] = useState("hidden");

  const setSnackbar = (message, status = "loading") => {
    setSnackbarMessage(message);
    setSnackbarStatus(status);
    if (status === "complete" || status === "error") {
      setTimeout(() => {
        setSnackbarStatus("hidden");
      }, 3000);
    }
  }

  /* Startup, get files and context */
  const [startup, setStartup] = useState("loading");
  useEffect(() => {
    const loadCurrentThreadMessages = async () => {
      try {
        const match = matchPath('/chat/c/:threadId', window.location.pathname);
        const threadId = match?.params?.threadId;
        if (threadId && threadId !== currentThreadID) {
          setCurrentThreadID(threadId);
          const newMessages = await getMessages(jwtToken, setSnackbar, threadId, setIsSendDisabled);
          setMessages(newMessages.messages.reverse());
        }
      } catch (error) {
        console.error(error);
        setSnackbar("Error retrieving messages", "error");
      }
    };
    //get all fields and user selected fields from hubspot
    const hubspotFields = async () => {
      const [afields, sfields] = await getHubSpotObjectToFields(jwtToken);
      setHubspotFields(afields);
      setHubspotSelectedFields(sfields);
    }

    const fetchData = async () => {
      if (jwtToken && !jwtTokenLoaded.current) {
        console.log('Running first load data fetch')
        jwtTokenLoaded.current = true;
        await loadCurrentThreadMessages();
        await new Promise(r => setTimeout(r, 750)); // sleep 0.75 second, because it feels jarring to have the window open so quickly.
        setStartup("done");
        let [fnames, gnames, hnames] = await getFileNames(jwtToken, setFileNames);
        setFileNames(fnames);
        setGoogleFileNames(gnames);
        // setHubspotObjects(hnames);
        let t = await getAllThreads(jwtToken);
        setThreads(t);
        if (!currentThreadID || !currentThreadArchived) {
          setIsSendDisabled(false);
        }
        let current_context = await getContext(jwtToken, setSnackbar);
        // .explanation is the top level context return structure
        setOrgContext(current_context?.explanation);
        const isHubSpotConnected = await connectHubspot(jwtToken, setHubspotConnectionStatus);
        if (isHubSpotConnected) {
          hubspotFields();
        }
      }
    };
    fetchData();
    console.log('Startup Finished');
  }, [jwtToken]);




  useEffect(() => {
    //making sure it's not triggering more than once
    if (jwtToken && authCode && service) {
      if (service === "hubspot" && localStorage.getItem('lastAuthenticatedService') !== "hubspot") {
        localStorage.setItem('lastAuthenticatedService', "hubspot");
        getHubSpotAuthCodeAndExport(jwtToken, authCode);
      }
      else if (service === "google" && localStorage.getItem('lastAuthenticatedService') !== "google") {
        //Google Step 5: Get the auth code and exchange it for an access token
        console.log('Getting Google Access Token', authCode);
        getGoogleAccessTokenFromAuthCode(jwtToken, setSnackbar, authCode, user, handleOpenPopup, setGoogleData);
        localStorage.setItem('lastAuthenticatedService', "google");
      }
    }
  }, [service, jwtToken]);

  //whenever active context file changes, update active context, if null set to empty dict
  useEffect(() => {
    const getNewContext = async () => {
      if (activeContextFile === "Organization Level Context") {
        setActiveContext(orgContext);
        parseCompanyInfo();
        return;
      }
      let newContext = await getContext(jwtToken, setSnackbar, [activeContextFile]);
      if (newContext) {
        setActiveContext(newContext[activeContextFile]);
      }
      return;
    }
    if (activeContextFile) {
      getNewContext();
    } else {
      setActiveContext("");
      if (selectedDetail === "Settings") {
        setSidemenuDetailsSize("small-details");
      }
    }
  }, [activeContextFile]);


  //whenever active thread changes, update url
  useEffect(() => {
    const updateMessagesAndURL = async () => {
      try {
        const match = matchPath('/chat/c/:threadId', window.location.pathname);
        const threadId = match?.params?.threadId;
        if (currentThreadID && threadId !== currentThreadID) {
          const url = new URL(window.location.href);
          if (!url.pathname.includes('/c/')) {
            url.pathname = `${url.pathname}/c/${currentThreadID}`;
          } else {
            const pathParts = url.pathname.split('/').filter(part => part !== '');
            pathParts[pathParts.length - 1] = currentThreadID;
            url.pathname = pathParts.join('/');
          }
          window.history.replaceState(null, "", url.toString());
          /*
            If threadId works for going from one thread to another, but what about when no thread is selected? 
            Functionally that looks the same as sending a clean message (thread ID goes from 0 to something else), but it's not the same since those times we do want to load the messages.
            So we need to check if the thread ID is different, and if the user didn't just send a message, and do this only then
            thats what messageFlag is for
          */
          if (threadId || !messageFlag) {
            setMessageFlag(false);
            const newMessages = await getMessages(jwtToken, setSnackbar, currentThreadID, setIsSendDisabled);
            setMessages(newMessages.messages.reverse());
          }
        }
      } catch (error) {
        console.error(error);
        setSnackbar("Error retrieving messages", "error");
      }
      setIsSendDisabled(currentThreadArchived);
    }
    updateMessagesAndURL();

  }, [currentThreadID]);

  useEffect(() => {
    console.log(threads, currentThreadID);
    const currentThread = threads.find(thread => thread[0] === currentThreadID);
    const currentThreadArchived = currentThread ? currentThread[1].archived : false;
    setCurrentThreadArchived(currentThreadArchived);
  }, [threads, currentThreadID]);

  const sendMessage = async () => {
    if (message) {
      let getThreadsAfter = !currentThreadID;
      try {
        await handleSend(jwtToken, audioOn, currentThreadID, setCurrentThreadID, message, setMessage, messages, setMessages, selectedModel, temperature, setIsSendDisabled);
      }
      catch (error) {
        console.error(error);
        setMessages([...messages, { content: "Sorry, I'm having trouble processing your request. Please try again.", role: 'assistant' }]);
      }
      //Commented out so follow up messages don't get cleared on recieving a message
      // setMessage("");
      setMessageFlag(true);

      if (getThreadsAfter) {
        let t = await getAllThreads(jwtToken);
        setThreads(t);
      }
    }
  }

  //Sidemenu Details Functions
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [detailsOpenStatus, setDetailsOpenStatus] = useState("details-closed");
  //used to collapse menu, either "" or "nomenu"
  const [noMenu, setNoMenu] = useState("");

  const checkDetailsOpenStatus = () => {
    return (detailsOpenStatus === "details-open" && selectedDetail ? "details-open" : "details-closed");
  }
  useEffect(() => {
    const checkScreenSizeAndUpdateStatus = () => {
      if (window.innerWidth < 1000) {
        setNoMenu("nomenu");
      }
    };


    // Call the function once to check the initial window size
    checkScreenSizeAndUpdateStatus();

    // Set up the event listener
    window.addEventListener("resize", checkScreenSizeAndUpdateStatus);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSizeAndUpdateStatus);
    };
  }, [noMenu]);

  const [sidemenuDetailsSize, setSidemenuDetailsSize] = useState("");
  //Sets active single select buttons
  const [activeButton, setActiveButton] = useState(null);

  const toggleDetails = () => {
    if (detailsOpenStatus === "details-open") {
      setDetailsOpenStatus("details-closed");
      if (selectedDetail) {
        console.log(selectedDetail)
        setSelectedDetail(null);
        setSidemenuDetailsSize("");
      }
    } else {
      setDetailsOpenStatus("details-open");
    }
  }
  const getActiveDetail = (detail) => { return (detailsOpenStatus === "details-open" && selectedDetail === detail) }
  const getSelectedDetail = (detail) => { return (selectedDetail === detail) }
  const switchDetail = (detail) => {
    if (!(detailsOpenStatus === "details-open" && selectedDetail !== detail)) {
      toggleDetails();
      //Clear all fields like searchquery, selected files, explanation, etc.
    }
    setActiveContextFile(null);
    setActiveButton(null);
    setContextSubmenu(null);
    if (detailsOpenStatus === "details-closed" || selectedDetail !== detail) {
      setSelectedDetail(detail);
    }
    if (detail === "Settings") {
      setSidemenuDetailsSize("small-details");
    }
    else {
      setSidemenuDetailsSize("");
    }
  }


  const renderTrendingTopics = () => {
    //selectedTT is selected trending topic
    const handleTTClick = (topic) => {
      setSelectedTT(topic);
    }

    function handlePromptClick(topic) {
      const randomIndex = Math.floor(Math.random() * trendingTopics[selectedTT][topic].length);
      setMessage(trendingTopics[selectedTT][topic][randomIndex]);
      toggleNotify();
      setTimeout(() => {
        setSelectedTT(null);
      }, 2000);

    }


    const trendingQuestions =
      <div className={`topic-section ${selectedTT ? 'fade-out' : ''}`}>
        {sortedTopics.map((topic, index) => (
          <div key={index} className={`topic`} onClick={() => handleTTClick(topic)} style={{ animationDelay: `${(selectedTT ? 0 : 3) + index * 0.5}s` }}>
            <div className={`number`}>{index + 1}</div>
            <div className={`divider`} />
            <div className={`label`} >{topic}</div>
            <i className={`material-icons-outlined`}> arrow_forward </i>
          </div>
        ))}
      </div>

    const trendingPrompts =
      <div className={`topic-section ${selectedTT ? '' : 'fade-out'}`}>
        {sortedPrompts.map((prompt, index) => (
          <div key={index} className={`topic`} onClick={() => handlePromptClick(prompt)} style={{ animationDelay: `${(selectedTT ? 3 : 0) + index * 0.5}s` }}>
            <div className={`label prompt`} >{prompt}</div>
            <i className={`material-icons-outlined`}> add </i>
          </div>
        ))}
      </div>


    return (
      <>
        <button className={`button sidemenu trending-button ${detailsOpenStatus === "details-open" || (detailsOpenStatus === "details-closed" && noMenu === "nomenu") ? "small" : "small invisible noclick"}`} onClick={() => {
          {
            if (detailsOpenStatus === "details-closed" && noMenu === "nomenu") {
              setNoMenu("");
            } else {
              setNoMenu(""); toggleDetails();
            }
          }
        }}>
          <i className={`material-icons-outlined`}> trending_up </i>
        </button >
        <div className={`trending-questions ${checkDetailsOpenStatus()} ${noMenu}`}>
          <div className={`section-header`}>
            <i className={`material-icons-outlined`}> trending_up </i>
            <div className={`text-header`}>
              {selectedTT ?
                <>
                  <div className={`todays`}>  {selectedTT} </div>
                  <div className={`subtitle`}>  Select Popular Prompts </div>
                </>
                :
                <>
                  <div className={`todays`}>  Today's </div>
                  <div className={`trending-topics`}> Trending Topics </div>
                </>
              }
            </div>
          </div>
          {trendingQuestions}
          {trendingPrompts}
          <div className={`description ${selectedTT ? "fade-out" : ""}`}> What other companies are <br></br> discussing </div>
          <div className={`section-footer ${selectedTT ? "" : "fade-out"}`}>
            <i className={`material-icons-outlined ${selectedTT ? "" : "fade-out"}`} onClick={() => setSelectedTT(null)}> keyboard_arrow_left </i>
          </div>
        </div>
      </>
    )
  }

  const modelsModule = process.env.REACT_APP_ENV === "dev" && possibleModels.length > 0 && (
    <>
      <div>
        <div className="section-header">Model Selection</div>
        <select
          value={selectedModel}
          onChange={(e) => setSelectedModel(e.target.value)}
          className="model-selector medium"
        >
          {possibleModels.map((model) => (
            <option key={model} value={model}>
              {model}
            </option>
          ))}
        </select>
      </div>
      <div>
        <div className="section-header">Model Temperature</div>
        <input type="number" className="temperature" value={temperature} step="0.1" min="0" max="2" onChange={(e) => setTemperature(e.target.value)} />
      </div>
    </>);

  const newChat = () => { setCurrentThreadID(null); setCurrentThreadArchived(false); setIsSendDisabled(false); setMessages([]); window.history.pushState(null, null, "/chat") }


  const renderSidemenu = () => {
    return (
      <div className={`sidemenu ${checkDetailsOpenStatus()} ${noMenu} flex`}>
        <div>
          <div className={`header ${detailsOpenStatus} ${noMenu}`}>
            <img src={logoSmall} alt="Logo" className={`logo ${detailsOpenStatus} ${noMenu}`} onClick={() => { setNoMenu(noMenu === "nomenu" ? "" : "nomenu") }} />
            <div className={`title ${detailsOpenStatus} ${noMenu}`} onClick={() => { setNoMenu(noMenu === "nomenu" ? "" : "nomenu") }}> maester </div>
          </div>
          <button className={`button sidemenu ${detailsOpenStatus === "details-open" || noMenu === "nomenu" ? "small" : ""}`} onClick={() => { newChat() }}>
            <i className={`material-icons-outlined`}> add_comment </i>
            <div className={`label`}>
              New Chat
            </div>
          </button>
          {renderTrendingTopics()}
        </div>
        <div style={{ marginBottom: "2.4rem" }}>
          <button className={`button sidemenu ${getActiveDetail("History") ? "active" : detailsOpenStatus === "details-open" || noMenu === "nomenu" ? "small" : ""}`} onClick={() => switchDetail("History")}>
            <i className={`material-icons-outlined ${getActiveDetail("History") ? "active" : detailsOpenStatus === "details-open" || noMenu === "nomenu" ? "small" : ""}`} >history</i>
            <div className={`label`}>
              History
            </div>
          </button>
          <button className={`button sidemenu ${getActiveDetail("Manage Data") ? "active" : detailsOpenStatus === "details-open" || noMenu === "nomenu" ? "small" : ""}`} onClick={() => switchDetail("Manage Data")}>
            <i className={`material-icons-outlined ${getActiveDetail("Manage Data") ? "active" : detailsOpenStatus === "details-open" || noMenu === "nomenu" ? "small" : ""}`} >hub</i>
            <div className={`label`}>
              Manage Data
            </div>
          </button>
          <button className={`button sidemenu ${getActiveDetail("Settings") ? "active" : detailsOpenStatus === "details-open" || noMenu === "nomenu" ? "small" : ""}`} onClick={() => switchDetail("Settings")}>
            <i className={`material-icons-outlined ${getActiveDetail("Settings") ? "active" : detailsOpenStatus === "details-open" || noMenu === "nomenu" ? "small" : ""}`} >settings</i>
            <div className={`label`}>
              Settings
            </div>
          </button>
        </div>
      </div>

    );
  }


  const renderHistorySidemenu = () => {
    return (
      <div className={getSelectedDetail("History") ? "menu no-overflow" : "menu hidden"}>
        <div className="section-header"> History </div>
        {renderThreads(isSendDisabled, threads, currentThreadID, setCurrentThreadID, currentThreadArchived, setCurrentThreadArchived)}
        <div className="section-detail"> Maester is constantly improving. <br /> <span className="section-detail primary clickable" style={{ textDecoration: "underline" }} onClick={() => handleOpenPopup("feedback")}> leave feedback anytime </span> </div>
      </div>
    )
  };

  return (
    <div className={`${theme} noscroll`}>
      <PopUp isThumbUp={popupThumbUp} setGoogleData={setGoogleData} googleData={googleData} isPopupOpen={isPopupOpen} popupClass={popupClass} messages={messages} fileNames={[...fileNames, ...googleFileNames]} handleClosePopup={handleClosePopup} setSnackbar={setSnackbar} />
      <div className={`startup ${isPopupOpen ? "popup" : ""} ${startup === "done" ? "done" : "loading"}`} onClick={() => { if (isPopupOpen) { handleClosePopup() } }}>
        <img src={logoSmall} alt="Logo" className="logo" />
      </div>
      <Snackbar
        snackbarStatus={snackbarStatus}
        snackbarMessage={snackbarMessage}
        setSnackbarStatus={setSnackbarStatus}
      />
      <div className="background flex between">
        <div className={`sidemenu-detail ${noMenu} ${sidemenuDetailsSize} ${checkDetailsOpenStatus()}`}>
          {renderHistorySidemenu()}
          {ManageData(activeButton, getSelectedDetail, setActiveButton, jwtToken, setSnackbar, setFileNames, fileNames, hubspotConnectionStatus, hubspotSelectedFields, setGoogleFileNames, googleFileNames, hubspotFields, hubspotSelectedObjects, setHubspotSelectedObjects, setActiveContextFile, setHubspotSelectedFields, searchQuery, setSearchQuery, selectedFiltered, setSelectedFiltered, user, setContextSubmenu, handleOpenPopup, googleData, setGoogleData, newChat, getAllThreads, setIsSendDisabled, isSendDisabled, setThreads)}
          {renderSettings(getSelectedDetail, theme, changeTheme, audioOn, setAudioOn, handleOpenPopup, user, setActiveContextFile, navigate, setContextSubmenu, setSidemenuDetailsSize, modelsModule)}
          {renderContext(user, activeContextFile, activeContext, setActiveContext, setActiveContextFile, jwtToken, setSnackbar, handleSetContext, setContextSubmenu, keywords, setKeywords, companyColors, setCompanyColors, generalContext, setGeneralContext, chartFormatting, setChartFormatting, getContext, setOrgContext, contextSubmenu)}
        </div>
        {renderSidemenu()}
        <div className={`chat ${sidemenuDetailsSize} ${noMenu} ${checkDetailsOpenStatus()}`}>
          <ChatWindow setIsThumbUp={setPopupThumbUp} currentThreadID={currentThreadID} messages={messages} setMessages={setMessages} toggleNotify={toggleNotify} setMessage={setMessage} username={user.username} jwtToken={jwtToken} setSnackbar={setSnackbar} handleOpenPopup={handleOpenPopup} detailsOpenStatus={detailsOpenStatus} />
          <form className="chat-input" onSubmit={(e) => { e.preventDefault(); }}>
            <textarea
              disabled={currentThreadArchived ? true : false}
              type="text"
              className={`input ${notify} ${currentThreadArchived ? "archived" : ""}`}
              placeholder={currentThreadArchived ? "Sending is disabled for archived threads" : "Type Your Message"}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey && !isSendDisabled) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
            />
            <button type="submit" className={`input-button ${isSendDisabled ? "disabled" : ""}`}>
              <ArrowUpwardOutlined className={`icon send-icon ${isSendDisabled ? "disabled" : ""}`} onClick={() => sendMessage()} />
            </button>
          </form>
          <span className="footer"> Maester can make mistakes. Consider checking important information.&nbsp; <span className="primary" style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => handleOpenPopup("feedback")}> Share your feedback anytime </span> </span>
        </div>
      </div>
    </div>
  );
}

export default Chat;
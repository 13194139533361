import { post, put } from 'aws-amplify/api';
import process from 'process';
import { getFileNames } from './Files';

//API Calls
export const handleGoogleButtonClick = async (jwtToken, setSnackbar, handleOpenPopup, setGoogleData) => {
  //Step 1: Check if the user has an access token in the database (requests to the backend API at GoogleAuthCheck index.py)
  let accessToken = await checkAccessToken(jwtToken);
  if (accessToken) {
    //Step 2b: User has access token
    // console.log("handleGoogleButtonClick: loading picker with access token", accessToken);
    loadGooglePicker(setSnackbar, accessToken, handleOpenPopup, setGoogleData);
  }
  else {
    //Step 2: If the user does not have an access token, initiate the OAuth flow
    console.log("handleGoogleButtonClick: no access token found, oauth flow initiated");
    initiateOAuthFlow();
  }
}

export const handleGoogleRefreshClick = async (jwtToken, setSnackbar, setGoogleFileNames, newChat, getAllThreads, setIsSendDisabled, setThreads) => {
  //Step 1: Check if the user has an access token in the database (requests to the backend API at GoogleAuthCheck index.py)
  setIsSendDisabled(true);
  let accessToken = await checkAccessToken(jwtToken);
  if (accessToken) {
    //Step 2b: User has access token
    console.log("Logged in: refreshing sheets");
    await refreshSheets(jwtToken, setGoogleFileNames, setSnackbar, newChat, getAllThreads, setThreads);
    setIsSendDisabled(false);
    return true;
  }
  else {
    //Step 2: If the user does not have an access token, initiate the OAuth flow
    console.log("handleGoogleRefreshClick: no access token found, oauth flow initiated");
    initiateOAuthFlow();
    return true;
  }
}



const checkAccessToken = async (jwtToken) => {
  // Step 1: Check if the user has an access token in the database (requests to the backend API at GoogleAuthCheck index.py)
  try {
    const responseBody = await (await post({
      apiName: 'MaesterV3API',
      path: '/api/v3/google/auth-check',
      options: {
        headers: { Authorization: jwtToken },
        body: {}
      }
    }).response).body.json();
    if (responseBody.access_token) {
      // Step 2b: User has access token
      // console.log("checkAccessToken: Access token found,", response.access_token);
      return responseBody.access_token;
    } else {
      // Step 2: If the user does not have an access token, initiate the OAuth flow
      // console.log("checkAccessToken: No access token found,", response.access_token);
      return null;
    }
  } catch (error) {
    console.error("checkAccessToken: Session Check Failed", error);
    return null;
  }
};


const initiateOAuthFlow = () => {
  console.log("Initiating OAuth flow");
  const clientId = process.env.REACT_APP_GOOGLE_API_CLIENT_ID;
  const encodedRedirectUri = encodeURIComponent(window.location.origin + '/google-callback');
  const scope = encodeURIComponent('https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/spreadsheets');
  const responseType = 'code';
  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodedRedirectUri}&response_type=${responseType}&scope=${scope}&access_type=offline&prompt=consent`;
  // Step 4: Redirect the user to the OAuth endpoint, will then send us to /google-callback (check Authhandlers).
  window.location.href = authUrl;
};


export const getGoogleAccessTokenFromAuthCode = async (jwtToken, setSnackbar, authCode, user, handleOpenPopup, setGoogleData) => {
  setSnackbar("logging into Google Sheets", "complete");
  try {
    const responseBody = await (await post({
      apiName: 'MaesterV3API',
      path: '/api/v3/google/token',
      options: {
        headers: { Authorization: jwtToken },
        body: {
          authorization_code: authCode,
          redirect_uri: window.location.origin + '/google-callback',
          user_role: user.role,
          organization: user.organization
        }
      }
    }).response).body.json();
    // Google Step 6: If successful, save the access token and open the picker
    if (responseBody.access_token) {
      loadGooglePicker(setSnackbar, responseBody.access_token, handleOpenPopup, setGoogleData);
      setSnackbar("Google Sheets Connection Established!", "complete");
    }
  } catch (error) {
    console.error("Handle Auth Failed", error);
  }
}

const loadGooglePicker = (setSnackbar, accessToken, handleOpenPopup, setGoogleData) => {
  //Google Step 7: Load the Google Picker API
  console.log("loadGooglePicker: loading picker");
  const script = document.createElement('script');
  script.src = 'https://apis.google.com/js/api.js';
  script.onload = () => {
    window.gapi.load('picker', () => {
      const pickerInstance = new window.google.picker.PickerBuilder()
        .addView(window.google.picker.ViewId.SPREADSHEETS)
        .setOAuthToken(accessToken)
        .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY)
        // .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
        .build();
      pickerInstance.setCallback((data) => pickerCallback(setSnackbar, data, accessToken, pickerInstance, handleOpenPopup, setGoogleData));
      pickerInstance.setVisible(true);
    }, (error) => {
      console.log('Error loading Picker API:', error);
    });
  };
  script.onerror = () => {
    console.log('Error loading Picker API');
  };
  document.body.appendChild(script);
};


const pickerCallback = async (setSnackbar, data, accessToken, pickerInstance, handleOpenPopup, setGoogleData) => {
  const action = data[window.google.picker.Response.ACTION];
  if (action === 'cancel') {
    pickerInstance.setVisible(false);
    return;
  }

  if (action === 'picked') {
    try {
      getTabNames(data, accessToken, handleOpenPopup, setGoogleData);
      pickerInstance.setVisible(false);
    } catch (error) {
      console.error("Error processing file:", error);
      setSnackbar("Failed to upload file", "error");
    }
  }
};


export const addSheet = async (jwtToken, user, googleData, setSnackbar) => {
  setSnackbar("connecting data...", "loading");
  console.log("Organization:", user.organization);
  console.log("Role:", user.role);
  console.log("Google Data:", googleData);
  const data = googleData.data;
  const file = data.docs[0];
  try {
    const response = await put({
      apiName: 'MaesterV3API',
      path: '/api/v3/google/manage-sheets',
      options: {
        headers: { Authorization: jwtToken },
        body: {
          file_id: file.id,
          file_name: file.name,
          last_updated: Date.now(),
          drive_last_modified: file.lastEditedUtc,
          tab_names: googleData.selectedTabNames,
        }
      }
    }).response;
    if (response) {
      console.log(`File ${file.name} updated successfully in database.`);
    }
    ;
    if (response) {
      console.log(`File ${file.name} updated successfully in database.`);
    }
  } catch (error) {
    console.error("update sheet Failed", error);
    setSnackbar("failed to connect", "error");
    return false;
  }
  return true;
}

const refreshSheets = async (jwtToken, setGoogleFileNames, setSnackbar, newChat, getAllThreads, setThreads) => {
  try {
    setSnackbar(`Refreshing data`, "loading");
    const responseBody = await (await post({
      apiName: 'MaesterV3API',
      path: '/api/v3/google/manage-sheets',
      options: {
        headers: { Authorization: jwtToken },
        body: {}
      }
    }).response).body.json();
    if (responseBody) {
      console.log(`Data successfully refreshed.`, responseBody);
      let googleFileNames = await getFileNames(jwtToken, setGoogleFileNames)
      setSnackbar(`Data successfully refreshed`, "complete");
      setGoogleFileNames(googleFileNames[1]);
    }
  } catch (error) {
    setSnackbar(`Error refreshing data`, "error");
    console.error("Refresh Sheets Failed", error);
    var t = await getAllThreads(jwtToken);
    setThreads(t);
    return false;
  }
  var t = await getAllThreads(jwtToken);
  setThreads(t);
  newChat();
  return true;
}

async function getTabNames(data, accessToken, handleOpenPopup, setGoogleData) {
  const fileId = data.docs[0].id
  const sheetsResponse = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${fileId}?fields=sheets.properties.title`, {
    headers: new Headers({
      'Authorization': `Bearer ${accessToken}`
    })
  });
  const sheetsData = await sheetsResponse.json();
  const tabNames = sheetsData.sheets.map(sheet => sheet.properties.title); // Log the sheet names
  setGoogleData({ data: data, tabNames: tabNames, selectedTabNames: [] });
  handleOpenPopup("google");
}


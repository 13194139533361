import React, { useState, useEffect, useRef } from "react"
import ReactMarkdown from 'react-markdown';
import PopUp from "../components/PopUp.jsx";
import remarkGfm from 'remark-gfm';
import "../styles/screens/ChatWindow/ChatWindow.css";
import { ContentCopy, LowPriority, ThumbDownOutlined, ThumbUpOutlined } from '@mui/icons-material';
import { getSuggestedQuestions } from "../functions/api/Ask";
import { rateAnswer } from "../functions/api/Feedback";
import { renderSuggestedQuestions } from "./suggestedQuestions";
import logoSmall from "../assets/small-logo.png";
import { type } from "@testing-library/user-event/dist/type/index.js";

function ChatWindow({ setIsThumbUp, currentThreadID, messages, setMessages, toggleNotify, setMessage, username, jwtToken, setSnackbar, handleOpenPopup, detailsOpenStatus }) {
    const [suggestedQuestions, setSuggestedQuestions] = useState([]);
    const [questionsLoaded, setQuestionsLoaded] = useState(false);
    const messagesEndRef = useRef(null);
    const containerRef = useRef(null);
    const handleThumbClick = (message, isThumbUp, index) => {
        rateAnswer(jwtToken, currentThreadID, message.message_id, isThumbUp);
        setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            updatedMessages[index].is_thumbs_up = isThumbUp ? "True" : "False";
            return updatedMessages;
        });
        setIsThumbUp(isThumbUp);
        handleOpenPopup("thumbs");
    };

    const copyImageToClipboard = async (image_data) => {
        try {
            // Convert base64 data to Blob
            const response = await fetch(image_data);
            const blob = await response.blob();
            // Create a new ClipboardItem
            const clipboardItem = new ClipboardItem({ [blob.type]: blob });
            // Write the ClipboardItem to the clipboard
            await navigator.clipboard.write([clipboardItem]);
            setSnackbar("message copied!", "complete");
        } catch (error) {
            setSnackbar("error copying image to clipboard", "error");
        }
    };


    const createMessage = (message, index) => {
        const isThumbUp = message.is_thumbs_up === null ? null : message.is_thumbs_up === "True";
        let processedRole;
        if (message.role === "assistant") {
            processedRole = "maester";
        } else if (message.role === "user") {
            processedRole = username || "user";
        }
        if (index > 0 && messages[index - 1].role === message.role && messages[index - 1].message_type !== "data_update") {
            processedRole = "";
        }
        // if (!message.message_id) {
        //     // console.log("no message id, corresponds to: ", message);
        // }
        function generateUniqueTenCharacterString() {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            let result = '';
            for (let i = 0; i < 10; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
        }
        const uniqueString = generateUniqueTenCharacterString();
        const imageKey = message.image_data ? "-image" + uniqueString : uniqueString;

        //data_update
        const messageType = message.message_type;

        return (
            (message.role === "user") ?
                <div key={"user-" + message.message_id} className="message-container user">
                    {processedRole && <div className="message-header user">{processedRole}</div>}
                    <div className="message-text user">
                        {message.content}
                    </div>
                    <div className="message-button-bar">
                        <div className="start-buttons">
                        </div>
                        <div className="end-buttons">
                            <ContentCopy className="message-button user" onClick={() => { navigator.clipboard.writeText(message.content); setSnackbar("message copied!", "complete"); }} />
                            <LowPriority className="message-button user" onClick={() => { setMessage(message.content); toggleNotify(); setSnackbar("message echoed!", "complete"); }} />
                        </div>
                    </div>
                </div>

                :

                (message.role === "assistant" && messageType !== "data_update") ?

                    <div key={"maester-" + message.message_id + imageKey} className="message-container maester">
                        {processedRole && <img className="message-header maester icon" src={logoSmall}></img>}
                        <div className="message-text maester">
                            <ReactMarkdown remarkPlugins={[remarkGfm]} >
                                {message.content && (message.content).replace("-MAESTERTABNAME-", " ")}
                            </ReactMarkdown>
                            {message.image_data && <img src={message.image_data} alt="image" className={"image-data"} />}
                        </div>
                        {/* don't show button bar for messages that are followed by an image */}
                        {!(message.image_data) && <div className="message-button-bar">
                            {message.key !== "loading-id" &&
                                <>
                                    <div className="start-buttons">
                                        <ThumbUpOutlined
                                            className={`message-button maester ${isThumbUp === true ? 'thumbs-up' : isThumbUp === false ? 'invisible' : ''}`}
                                            onClick={() => handleThumbClick(message, true, index)}
                                        />
                                        <ThumbDownOutlined
                                            className={`message-button maester ${isThumbUp === true ? 'invisible' : isThumbUp === false ? 'thumbs-down' : ''}`}
                                            onClick={() => handleThumbClick(message, false, index)}
                                        />
                                    </div>
                                    <div className="end-buttons">
                                        <ContentCopy className="message-button maester" onClick={() => {
                                            if (message.image_data) {
                                                copyImageToClipboard(message.image_data)
                                            } else {
                                                navigator.clipboard.writeText(message.content);
                                                setSnackbar("message copied!", "complete");
                                            };

                                        }} />
                                    </div>
                                </>}
                        </div>}
                    </div>

                    :

                    (message.role === "assistant" && messageType === "data_update") ?

                        <div key={"data-update-" + message.message_id + index} className="data-update">
                            <span>&#x21bb;</span>
                            <span>The following data sources have been refreshed since the last time you used this thread: <strong>{message.content}</strong></span>
                        </div>

                        :
                        ""
        );
    }

    useEffect(() => {
        const getSuggested = async () => {
            let suggested = await getSuggestedQuestions(jwtToken);
            if (suggested) {
                setSuggestedQuestions(suggested);
            }
        }

        if (window.location.pathname === "/chat" && jwtToken) {
            setQuestionsLoaded(false);
            getSuggested();
        }

        //so that it doesn't render example questions immediately if we're in a converstation and then open a new one
        else if (window.location.pathname !== "/chat") {
            setQuestionsLoaded(false);
        }

    }, [window.location.pathname, jwtToken]);

    useEffect(() => {
        if (suggestedQuestions.length > 0) {
            setTimeout(() => {
                setQuestionsLoaded(true);
            }, 450);
        }
    }, [suggestedQuestions]);

    useEffect(() => {
        const scrollToBottom = () => {
            const container = containerRef.current;
            if (container) {
                //if user scrolls more than 1 "chat window height" away, don't autoscroll
                const isNearBottom = container.scrollHeight - container.scrollTop - container.clientHeight < container.clientHeight * 1.2;
                if (isNearBottom) {
                    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
                }
            }
        };

        if (messages.length > 0) {
            scrollToBottom();
        }
    }, [messages]);

    const pushDataUpdateMessage = (displayMessages, data_update_sources, i) => {
        if (messages.length === 0 || data_update_sources.size === 0) return

        const copied_prev_message = JSON.parse(JSON.stringify(messages[i - 1]));
        copied_prev_message.content = Array.from(data_update_sources).join(", ")
        displayMessages.push(copied_prev_message);
        data_update_sources.clear();
    }

    let data_update_sources = new Set();
    let displayMessages = [];
    for (let i = 0; i < messages.length; i++) {
        if (messages[i].message_type === "data_update") {
            data_update_sources.add(messages[i].content.replace(/.*data source (\w+).*/, "$1"))
        } else {
            pushDataUpdateMessage(displayMessages, data_update_sources, i);
            const copied_message = JSON.parse(JSON.stringify(messages[i]));
            displayMessages.push(copied_message);
        }
    }

    pushDataUpdateMessage(displayMessages, data_update_sources, messages.length)
    return (
        <div ref={containerRef} className={`chat-window ${detailsOpenStatus} flex`}>
            {
                displayMessages.length > 0 ?

                    displayMessages.map((message, index) => (
                        createMessage(message, index)))
                    :
                    renderSuggestedQuestions(suggestedQuestions, questionsLoaded, setMessage, toggleNotify)

            }
            <span ref={messagesEndRef} />
        </div>
    );
}

export default ChatWindow;
